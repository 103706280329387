<template>
    <form class="form-create-event" @submit.prevent="submitForm" ref="formCreateEvent">
        <div class="title">
            Створення події
        </div>
        <div class="inputs-container">
            <div class="inputs-left-block">
                <div class="form-row">
                    <span v-if="errors.title" class="error-label">{{errors.title}}</span>
                    <label class="item-label" for="event-name">Назва події</label>
                    <input name="title" id="event-name" type="text">
                </div>
                <div class="form-row">
                    <span v-if="errors.date" class="error-label">{{errors.date}}</span>
                    <DatepickerComponent
                        label="Дата заходу"
                        required
                        name="date"
                    />
                </div>
            </div>
            <div class="inputs-right-block">
                <div class="form-row">
                    <span v-if="errors.category_id" class="error-label">{{errors.category_id}}</span>
                    <CustomSelectV2
                        :options="categoriesList"
                        v-model="categoryId"
                        required="required"
                        label="Категорія"
                        name="category_id"
                    />
                </div>
                <div class="form-row time-inputs">
                    <div class="form-time">
                        <span v-if="errors.start_time" class="error-label">{{errors.start_time}}</span>
                        <label class="item-label">Час початку</label>
                        <v-date-picker :is24hr="format24h" :minute-increment="5" mode="time" v-model="dateStart" :timezone="timezone" />
                    </div>
                    <div class="form-time">
                        <span v-if="errors.end_time" class="error-label">{{errors.end_time}}</span>
                        <label class="item-label">Час завершення</label>
                        <v-date-picker mode="time" :is24hr="format24h" :minute-increment="5" v-model="dateFinish" :timezone="timezone" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-description">
            <span class="textarea-label">Опис</span>
            <textarea
                name="text"
                class="form-textarea"
                type="text"
                v-model="description"
            >
            </textarea>
            <span v-if="errors.text" class="error-label">{{errors.text}}</span>
        </div>
        <div class="row-form">
            <div class="form-file-container">
                <span v-if="errors.photo" class="error-label">{{errors.photo}}</span>
                <span class="input-label">Додати файл</span>
                <input hidden type="file" id="add-file" ref="addFile" @change="fileUpload()"/>
                <label class="label-for-file" for="add-file"><span v-if="file">{{file.name}}</span></label>
            </div>
            <div class="btn-container">
                <button class="btn-form" type="submit">Створити</button>
                <button class="btn-form disableBtn" @click="disableCreation">Скасувати</button>
            </div>
        </div>
    </form>
</template>

<script>
import CustomSelectV2 from "@/components/ui/CustomSelectV2";
import DatepickerComponent from "../components/ui/DatepickerComponent";
import EventService from "../services/EventService";

export default {
    name: "FormCreateEvent",
    components: {
        CustomSelectV2,
        DatepickerComponent
    },
    data () {
        return {
            categoriesList: [],
            categoryId: null,
            dateEvent: null,
            description: null,
            file: null,
            timezone: '',
            dateStart: new Date(),
            dateFinish: new Date(),
            format24h: true,
            timeStart: null,
            timeFinish: null,
            errors: {}
        }
    },
    watch:{
        dateStart(){
            this.timeStart = this.reformatTime(this.dateStart)
        },
        dateFinish(){
            this.timeFinish = this.reformatTime(this.dateFinish)
        }
    },
    methods: {
        fileUpload(){
            this.file = this.$refs.addFile.files[0];
        },
        async submitForm(){
            let formData = new FormData(this.$refs.formCreateEvent);
            formData.append('start_time', this.timeStart)
            formData.append('end_time', this.timeFinish)
            formData.append('photo', this.file)
/*             console.log(this.timeStart < this.timeFinish);
            return false */

            let resp = await EventService.createEvent(formData).catch(this.handleError)
            if (resp){
                this.disableCreation()
            }
        },
        reformatTime(date){
            let hours = date.getHours()
            let minutes = date.getMinutes();
            if (hours < 10) { hours = `0${hours}` }
            if (minutes < 10) { minutes = `0${minutes}` }
            return(`${hours}:${minutes}`)
        },
        async getCategoriesList(){
            let response = await EventService.getEventCategories()
            response.forEach(event => {
                this.categoriesList.push(
                    {"label": event.value, 'value': event.id}
                )
            })
        },
        getTimeNow(){
            this.timeFinish = this.reformatTime(this.dateFinish)
            this.timeStart = this.reformatTime(this.dateStart)
        },
        handleError(resp){
            if (resp?.statusCode === 422) {
                this.errors = resp.data
            }
        },
        disableCreation(){
            this.$emit('disableCreation', false)
        }
    },
    created() {
        this.getCategoriesList()
        this.getTimeNow()
    }
}
</script>

<style scoped lang="less">
    .form-create-event{
        width: 67%;
        margin: 50px 120px;
        overflow-x: hidden;
        
        @media (max-width: 992px){
            width: 80%;
            margin: 0 auto;
        }

        @media screen and (max-width: 575px) {
             width: 90%;
        }

        .d-none{
            display: none;
        }
        .title{
            font-family: @secondFontFamily;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: -0.17px;
            color: #1a2744;
            display: flex;
            justify-content: center;
            margin-top: 143px;
            @media (max-width: 992px){
                margin-top: 40px;
            }
        }
        .inputs-container{
            display: flex;
            justify-content: space-between;
            margin-top: 80px;

            @media (max-width: 992px){
                flex-direction: column;
                justify-content: center;
                margin-top: 40px;
            }
            .item-label{
                white-space: nowrap;
                font-size: 12px;
                line-height: 1.33;
                color: #1a2744;
                margin-bottom: 5px;
                margin-left: 20px;
            }
            .inputs-left-block, .inputs-right-block{
                display: flex;
                flex-direction: column;
                width: 46%;
                @media (max-width: 992px){
                    width: 100%;
                }
                .form-row{
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    input{
                        height: 50px;
                        border-radius: 10px;
                        border: solid 1px #cdd4e0;
                        background-color: #fff;
                        outline: none;
                        padding-left: 20px;
                        font-size: 16px;
                        color: #1a2744;
                        &:focus{
                            border: solid 1px #ffc900;
                        }
                    }
                }
                .time-inputs{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .form-time{
                        display: flex;
                        flex-direction: column;
                        width: 47%;

                        ::v-deep .vc-date-time .vc-date{
                            display: none;
                        }
                    }
                }
            }
        }
        .form-description{
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            .textarea-label{
                font-size: 12px;
                line-height: 1.33;
                color: #1a2744;
                margin-bottom: 5px;
                margin-left: 20px;
            }
            .form-textarea{
                display: block;
                outline: none;
                width: 100%;
                height: 280px;
                border: 1px solid #cdd4e0;
                border-radius: 10px;
                font-size: 16px;
                font-weight: 500;
                padding-left: 20px;
                padding-top: 20px;
                resize: none;
                &:focus{
                    border: solid 1px #ffc900;
                }
            }
        }
        .form-file-container{
            margin-top: 20px;
            width: 46%;
            @media (max-width: 992px){
                width: 80%;
            }
            .input-label{
                font-size: 12px;
                line-height: 1.33;
                color: #1a2744;
                margin-bottom: 5px;
                margin-left: 20px;
            }
            .label-for-file{
                border-radius: 10px;
                width: 430px;
                height: 50px;
                border: solid 1px #cdd4e0;
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 20px;
                margin-top: 5px;
                width: 100%;
                &:after{
                    content: url('../assets/images/load.svg');
                    cursor: pointer;
                    position: absolute;
                    background-color: #ffc900;
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    right: -22%;
                }
            }
        }
        .btn-container{
            margin-top: 41px;
            width: 42%;
            @media (max-width: 992px){
                width: 100%;
            }
            .btn-form{
                width: 220px;
                height: 50px;
                border-radius: 10px;
                box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
                background-color: #ffc900;
                font-family: @secondFontFamily;
                font-size: 18px;
                font-weight: 600;
                color: #fff;
                width: 46%;

                &:first-child{
                    margin-right: 10px;
                }
            }
            .disableBtn{
                //cursor: default;
                background-color: #cdd4e0;
                box-shadow: none;
            }
        }

        .row-form{
            display: flex;
            justify-content: space-between;
            width: 100%;
            @media (max-width: 992px){
                flex-direction: column;
            }
        }

        ::v-deep .vc-pane-container .vc-title{
            color: #1a2744!important;
        }
        ::v-deep .vc-arrow svg{
            color: #718096;
        }
        ::v-deep .vc-time-picker svg{
            display: none;
        }
        ::v-deep .form-time .vc-container{
            width: 100%;
            &:before{
                content: url("../assets/images/yellow-clock.svg");
                position: relative;
                left: 80%;
                top: 25%;
                z-index: 5;
            }
        }
        .error-label{
            font-size: 12px;
            color: #ffc900;
            height: 0;
            display: block;
            position: relative;
            bottom: 0;
            left: 0;

            @media screen and (max-width: 575px) {
                font-size: 10px;
            }
        }
        ::v-deep{
            .vc-time .vc-select select{
                background-color: white;
                border: none;
                padding: 0;
                width: auto;
            }
        }
    }
</style>
