<template>
    <section class="calendar">
        <div class="calendar-wrapper" >
            <CommonBlock
                title="Календар подій"
                :visible-back-btn="true"
                :breadcrumbs="breadcrumbs"
                :cancelBackBtnEventPassed="true"
                class="calendar-common"
                @clickBack="clickBack"
            >
                <div class="calendar-wrapper__slot" :class="{'party' : !isDeputy}">
                    <div class="calendar-container" :class="{'calendar-container--loading' : isClicked}">
                        <v-calendar
                            locale="uk"
                            title-position="left"
                            :attributes="attrs"
                            @dayclick='dayClicked'
                            @click="getMonthEvents"
                            @update:to-page="pageChange"
                        />
                    </div>
                    <div class="calendar-filter" >
                        <div>
                            <input @click="filterEvents(allCategories[0])" type="checkbox" id="meetings" value="Вуличні акції/мітинги" v-model="checkedFilters">
                            <label for="meetings">Вуличні акції/мітинги</label>
                            <svg class="calendar-filter_icon calendar-filter_icon-meetings" width="40" height="24" viewBox="0 0 40 24" fill="none"  xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="24" rx="12" fill="#F40035"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8053 6.44662C26.0417 6.16275 26.3893 6 26.7585 6C27.4434 6 28 6.55665 28 7.24152V16.7584C28 17.4433 27.4434 17.9999 26.7585 18C26.3894 18 26.0417 17.8372 25.8054 17.5534L25.2709 16.9121C24.7787 16.3216 24.1036 15.7435 23.3008 15.2226C23.1074 16.4179 22.0684 17.3333 20.8197 17.3333H17.1804C15.7943 17.3333 14.6667 16.2057 14.6667 14.8196V14.5136C14.6667 14.097 14.7695 13.6933 14.9629 13.3333H14.3333C14.1497 13.3333 14 13.4831 14 13.6666C14 14.2181 13.5514 14.6666 13 14.6666C12.4486 14.6666 12 14.2181 12 13.6666V10.3333C12 9.78189 12.4486 9.33333 13 9.33333C13.5514 9.33333 14 9.78189 14 10.3333C14 10.5169 14.1497 10.6667 14.3333 10.6667H19.0534C20.8066 10.6667 23.2363 9.52864 24.3594 8.18099L25.8053 6.44662ZM20.8197 15.3333C21.1029 15.3333 21.3333 15.1028 21.3333 14.8196V14.5136C21.3333 14.2304 21.1029 13.9999 20.8197 13.9999H17.1803C16.8971 13.9999 16.6667 14.2304 16.6667 14.5136V14.8196C16.6667 15.1028 16.8971 15.3333 17.1803 15.3333H20.8197Z" fill="white"/>
                            </svg>
                            <span>{{allCategories[0]}}</span>
                        </div>
                        <div>
                            <input @click="filterEvents(allCategories[1])" type="checkbox" id="advicesForEvents" value="Рекомендації до проведення свят/заходів" v-model="checkedFilters">
                            <label for="advicesForEvents" >Рекомендації до проведення свят/заходів</label>
                            <svg class="calendar-filter_icon calendar-filter_icon-advicesForEvents" width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="24" rx="12" fill="#18B4F8"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4601 15.4947L21.8853 6.13582C21.4638 5.4247 20.7195 5 19.8942 5C19.069 5 18.3246 5.4247 17.9032 6.13582L12.3288 15.4947C11.8983 16.2208 11.8902 17.0928 12.3066 17.827C12.724 18.5617 13.476 19 14.3194 19H25.4691C26.3124 19 27.0645 18.5617 27.4818 17.827C27.8983 17.0928 27.8902 16.2208 27.4601 15.4947ZM19.8943 7.86132C20.4057 7.86132 20.8203 8.27687 20.8203 8.78953V12.5024C20.8203 13.015 20.4057 13.4307 19.8943 13.4307C19.3828 13.4307 18.9682 13.015 18.9682 12.5024V8.78953C18.9681 8.27687 19.3828 7.86132 19.8943 7.86132ZM18.5051 15.7512C18.5051 16.519 19.1282 17.1435 19.8943 17.1435C20.6603 17.1435 21.2834 16.519 21.2834 15.7512C21.2834 14.9834 20.6603 14.3588 19.8943 14.3588C19.1282 14.3588 18.5051 14.9834 18.5051 15.7512Z" fill="white"/>
                            </svg>
                            <span>{{allCategories[1]}}</span>

                        </div>
                        <div>
                            <input @click="filterEvents(allCategories[2])" type="checkbox" id="localIniciatives" value="Місцеві ініціативи" v-model="checkedFilters">
                            <label for="localIniciatives">Місцеві ініціативи</label>
                            <svg class="calendar-filter_icon calendar-filter_icon-localIniciatives" width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="24" rx="12" fill="#A7DA2A"/>
                                <path d="M19.5625 18.3333H19.0807C18.8775 17.5735 17.9938 17 16.9375 17H16.0625C15.0062 17 14.1225 17.5735 13.9193 18.3333H13.4375C13.1957 18.3333 13 18.4824 13 18.6667C13 18.8509 13.1957 19 13.4375 19H19.5625C19.8043 19 20 18.8509 20 18.6667C20 18.4824 19.8043 18.3333 19.5625 18.3333Z" fill="white"/>
                                <path d="M25.7734 5.77729C25.6348 5.70952 25.4707 5.72854 25.35 5.82604C24.2867 6.68913 22.9133 6.68913 21.85 5.82604C20.6905 4.88572 19.2466 4.75164 18 5.40624V12.0651C19.0406 11.3163 20.3351 11.3444 21.35 12.1682C22.0336 12.7226 22.8168 13 23.6 13C24.3832 13 25.1664 12.7226 25.85 12.1682C25.9449 12.0909 26 11.9744 26 11.8512V6.14307C26 5.98695 25.9117 5.84506 25.7734 5.77729Z" fill="white"/>
                                <path d="M16 5C15.4477 5 15 5.37689 15 5.84184V16H17V5.84184C17 5.37689 16.5523 5 16 5Z" fill="white"/>
                            </svg>
                            <span>{{allCategories[2]}}</span>

                        </div>
                        <div>
                            <input @click="filterEvents(allCategories[3])" type="checkbox" id="communityEvents" value="Партійні заходи" v-model="checkedFilters">
                            <label for="communityEvents">Партійні заходи</label>
                            <svg class="calendar-filter_icon calendar-filter_icon-communityEvents" width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="24" rx="12" fill="#B601D9"/>
                                <ellipse cx="19.9265" cy="7.67647" rx="2.77941" ry="2.67647" fill="white"/>
                                <circle cx="25.4853" cy="8.80859" r="1.75" fill="white"/>
                                <circle cx="14.5735" cy="8.80859" r="1.75" fill="white"/>
                                <path d="M16.1176 12.0677C15.4533 11.5189 14.8517 11.5916 14.0835 11.5916C12.9347 11.5916 12 12.5284 12 13.6797V17.0588C12 17.5588 12.4047 17.9653 12.9025 17.9653C15.0512 17.9653 14.7924 18.0045 14.7924 17.8719C14.7924 15.4775 14.5111 13.7216 16.1176 12.0677Z" fill="white"/>
                                <path d="M20.6787 11.6249C19.3166 11.512 18.1326 11.6262 17.1114 12.4635C15.4024 13.8232 15.7313 15.6539 15.7313 17.8936C15.7313 18.4862 16.2166 18.9773 16.8223 18.9773C23.3982 18.9773 23.6599 19.188 24.0498 18.3302C24.1777 18.0402 24.1427 18.1323 24.1427 15.3574C24.1427 13.1534 22.2213 11.6249 20.6787 11.6249Z" fill="white"/>
                                <path d="M25.9753 11.5915C25.2029 11.5915 24.6046 11.5196 23.9412 12.0677C25.5357 13.7092 25.2665 15.3453 25.2665 17.8717C25.2665 18.0051 25.0516 17.9651 27.1241 17.9651C27.6396 17.9651 28.0588 17.544 28.0588 17.0264V13.6796C28.0588 12.5283 27.1241 11.5915 25.9753 11.5915Z" fill="white"/>
                            </svg>
                            <span>{{allCategories[3]}}</span>

                        </div>
                    </div>
                </div>
            </CommonBlock>

            <div v-if="!createEvent" class="calendar-events-list">
                <div class="calendar-event_header">
                    <h3>{{selectedDate.getDate()}} {{allMonthes[titleDate]}}</h3>
                    <h5>Заплановані події на сьогодні</h5>
                </div>
                <div v-if="checkedFilters.length === allCategories.length" class="calendar-events-list__itemWrapper">
                    <event-card v-for="(event, index) in events"
                                :id="event.id"
                                :photo="event.photo"
                                :class="event.class"
                                :title="event.title"
                                :category="event.category"
                                :start_time="event.start_time"
                                :end_time="event.end_time"
                                @selectEvent="selectEvent"
                                :key="index"
                                :date="event.date"
                                :selectedEventId="selectedId" />
                </div>
                <div class="calendar-events-list__itemWrapper" v-else>
                    <event-card v-for="(event, index) in filteredEvents"
                                :id="event.id"
                                :photo="event.photo"
                                :class="event.class"
                                :title="event.title"
                                :category="event.category"
                                :start_time="event.start_time"
                                :end_time="event.end_time"
                                @selectEvent="selectEvent"
                                :key="index"
                                :date="event.date"
                                :selectedEventId="selectedId" />

                </div>
                <div class="btn-create-event">
                    <CustomButton @click="createEvent = true" label="Створити подію"/>
                    <div @click="activeHelpList = !activeHelpList" class="tooltip-btn">Як це працює?
                        <div v-if="activeHelpList" class="help-list">
                            <p class="close_btn">x</p>
                            <p>Користувачі платформи можуть запропонувати подію, яка, після перевірки адміністратором, буде відображена тут - у календарі подій.</p>
                            <p>Для цього потрібно зробити кілька кроків:</p>
                            <ol>
                                <li>Клацнути на кнопку «Створити подію».</li>
                                <li>Заповнити всі необхідні поля у формі.</li>
                                <li>Клацнути «Створити».</li>
                            </ol>
                            <p>Діліться своїми ідеями з іншими партійцями всього в пару кліків!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectedEvent && !createEvent" class="calendar-event">
                <div class="calendar-event_header">
                    <h4>{{ selectedEvent.title }}</h4>
                    <h5>{{ selectedEvent.category }}</h5>
                </div>

                <div class="calendar-event_body">
                    <div v-if="selectedEvent.photo" class="calendar-event_img">
                        <img :src="selectedEvent.photo" alt="people"/>
                    </div>
                    <p class="calendar-event_text" v-html="selectedEvent.text"></p>
                </div>
                <div class="calendar-event_footer">
                    <CustomButton @click="eventUrl" label="До заходу"/>
                </div>
            </div>

            <article class="content custom-scroll" v-if="createEvent">
                <FormCreateEvent @disableCreation="disableCreation" />
            </article>
        </div>
        <ActionPanel/>
    </section>
</template>


<script>
    import CustomButton from "@/components/CustomButton";
    import CommonBlock from "../components/CommonBlock";
    import ActionPanel from "../components/ActionPanel";
    import EventCard from "../components/EventCard";
    import EventService from "../services/EventService";
    import FormCreateEvent from "../components/FormCreateEvent";

    export default {
        locale: "ua-UA",
        name: 'events',
        components: {
            EventCard,
            CustomButton,
            CommonBlock,
            ActionPanel,
            FormCreateEvent
        },
        data() {
            return {
                selectedDate: new Date(),
                selectedEvent: null,
                monthEvents: [],
                filteredMonthEvents: [],
                events: [],
                selectedMonth: null,
                checkedFilters: ["Вуличні акції/мітинги", "Рекомендації до проведення свят/заходів", "Місцеві ініціативи", "Партійні заходи"],
                allCategories: ["Вуличні акції/мітинги", "Рекомендації до проведення свят/заходів", "Місцеві ініціативи", "Партійні заходи"],
                allMonthes: ['Січня','Лютого','Березня','Квітня','Травня','Червня','Липня','Серпня', 'Вересня', 'Жовтня', 'Листопада', 'Грудня' ],
                breadcrumbs: [
                    {id: 1, name: 'Календар подій'}
                ],
                createEvent: false,
                isClicked: false,
                isDeputy: this.$store.getters.identity.is_deputy,
                activeHelpList: false,
            };
        },

        beforeUpdate() {
            if (this.selectedEvent && !this.checkedFilters.includes(this.selectedEvent.category)) {
                this.selectedEvent = null
                this.selectedId = null
            }
            this.sortEvents(this.events);
            this.showCurrentEvent()
        },

        computed: {
            selectedId() {
                if (this.selectedEvent) {
                    return this.selectedEvent.id
                }
            },
            filteredEvents() {
                let filtered = this.events.filter(event => this.checkedFilters.includes(event.category));
                this.sortEvents(filtered);
                return filtered;
            },
            titleDate() {
                return this.selectedDate.getMonth()
            },
            attrs: function() {
                return [
                    {
                        key: 'today',
                        highlight: {
                            color: 'yellow',
                            fillMode: 'solid',
                            class: 'highlight-selected'
                        },
                        dates: new Date(),
                    },
                    {
                        key: 'daySelected',
                        highlight: {
                            color: 'yellow',
                            fillMode: 'solid',
                            class: 'highlight'
                        },
                        dates: this.selectedDate,
                    },
                    {
                        dot: {
                            class: 'dots',
                        },
                        dates: this.getDotAttrs(),
                    },
                    {
                        dot: {
                            class: 'dots dots--past',
                        },
                        dates: this.getPastDotAttrs(),
                    },

                ]
            },
        },

        watch: {
            selectedEvent() {
                if (this.showCurrentEvent()) {
                    this.selectedId = this.showCurrentEvent().id
                }
                return this.showCurrentEvent()
            }
        },

        async created() {
            if (this.$router.data) {
                this.selectedDate = new Date(this.$router.data.id)
            }
            await this.getMonthEvents();
            await this.updateEvents(this.selectedDate);
            this.showCurrentEvent()

        },
        methods: {
            clickBack() {
                this.$router.go(-1)
            },
            async dayClicked(day) {
                this.selectedEvent= null,
                await this.updateEvents(day.date);
                this.selectedDate = day.date;
            },
            async selectEvent(id) {
                this.selectedId = id
                this.selectedEvent = await EventService.getEvent(id);

                if (window.innerWidth <= 1100) {
                    this.eventUrl();
                }
            },
            async updateEvents(date) {
                this.events = await EventService.getEvents(date.getDate(), date.getMonth() + 1, date.getFullYear());
            },
            eventUrl() {
                this.$router.push(`/events/${this.selectedId}`)
            },
            async getMonthEvents() {
                console.log('sua');
                if (this.isClicked) {
                    console.log('clicked');
                    return false
                }
                this.isClicked = true
                this.monthEvents = []
                let date;
                if (this.selectedMonth) {
                    date = new Date(this.selectedMonth.year, this.selectedMonth.month-1)
                } else {
                    date = new Date()
                }

                const zero = await EventService.getMonth(date.getMonth() + 1, date.getFullYear(), '0')
                const first = await EventService.getMonth(date.getMonth() + 1, date.getFullYear(), '1')
                const second = await EventService.getMonth(date.getMonth() + 1, date.getFullYear(), '2')
                const third = await EventService.getMonth(date.getMonth() + 1, date.getFullYear(), '3')

                if (this.checkedFilters.includes(this.allCategories[0])) {
                    this.monthEvents.push(...zero)
                }
                 if (this.checkedFilters.includes(this.allCategories[1])) {
                    this.monthEvents.push(...first)
                }
                 if (this.checkedFilters.includes(this.allCategories[2])) {
                    this.monthEvents.push(...second)
                }
                 if (this.checkedFilters.includes(this.allCategories[3])) {
                    this.monthEvents.push(...third)
                }
                this.isClicked = false
            },
            showCurrentEvent() {
                if (!this.selectedEvent) {
                    const currentDate = new Date()
                    this.filteredEvents.reverse()
                    this.filteredEvents.forEach((event, index) => {
                        let date = new Date()
                        let eventDate = new Date(event.date)
                        date.setHours(0, 0, 0, 0)
                        eventDate.setHours(0, 0, 0, 0)
                        date = date.getTime()
                        eventDate = eventDate.getTime()

                        if (date == eventDate) {
                            const [hourEnd, minutesEnd] = event.end_time.split(":")
                            const [hourStart, minutesStart] = event.start_time.split(":")
                            if (hourStart < currentDate.getHours() && currentDate.getHours() < hourEnd) {
                                    this.selectedEvent = event
                            } else if ((hourStart < currentDate.getHours() && currentDate.getHours() == hourEnd) && currentDate.getMinutes() < minutesEnd) {
                                    this.selectedEvent = event
                            } else if (hourStart == currentDate.getHours() && currentDate.getMinutes() >= minutesStart) {
                                    this.selectedEvent = event
                            }
                        }
                    })
                }
            },
            getDotAttrs() {
                const array = []
                let date
                if (this.selectedMonth) {
                    date = new Date(this.selectedMonth.year, this.selectedMonth.month - 1)
                } else {
                    date = new Date(this.selectedDate)
                }
                let currentDate = new Date().getDate();
                this.monthEvents.forEach(ev => {
                    if (currentDate < ev) {
                        array.push(date.setDate(ev))
                    }
                })
                return array
            },
            getPastDotAttrs() {
                const array = []
                let date
                if (this.selectedMonth) {
                    date = new Date(this.selectedMonth.year, this.selectedMonth.month - 1)
                } else {
                    date = new Date(this.selectedDate)
                }
                let currentDate = new Date().getDate();
                this.monthEvents.forEach(ev => {
                    if (currentDate > ev) {
                        array.push(date.setDate(ev))
                    }
                })
                return array
            },
            sortEvents(events) {
                events.sort(function compare(next, prev) {
                    if (next.start_time < prev.start_time) {
                        return -1;
                    }
                    if (next.start_time > prev.start_time) {
                        return 1;
                    }
                    return 0;
                })
            },
            pageChange(page) {
                this.selectedMonth = page;
                this.getMonthEvents()
            },
            async filterEvents() {
                this.getMonthEvents()

            },
            disableCreation(data){
                this.createEvent = data
            }
        },
        destroyed() {
            this.createEvent = false
        }
    };
</script>

<style scoped lang="less">

    article.content {
        height: 100vh;
        width: 100vw;
        overflow-y: auto;
    }

    .calendar-container{
        margin-bottom: 20px;

        &--loading {
            position: relative;

            &::after {
                content: "";
                background-color: #ffc80071;
                left: 0;
                top: 0;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 10;
            }
        }
    }
    .vc-container {
        width: 100%;
        background: transparent;
        border: none;
        color: #fff !important;
    }

    .vc-pane {
        max-width: 100%;
    }

    .vc-title {
        color: #fff !important;
    }

    .vc-focusable {
        background: #ffc900 !important;
    }

    .vc-header {
        color: #fff !important;
        border-bottom: 2px solid #ddd;
    }

    .btn-create-event{
        position: absolute;
        bottom: 30px;
        margin-left: 40px;
        display: flex;

        @media screen and (max-width: 980px) {
            position: initial;
            margin: 50px 0 20px 20px
        }
    }

    .tooltip-btn{
        //width: 160px;
        height: 50px;
        margin: 0px 0 0 18px;
        padding: 0 0 0 35px;
        color: #1a2744;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: @secondFontFamily;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;

        &::before {
            content: "i";
            background-color: #ffc900;
            left: 0;
            top: 15px;
            position: absolute;
            text-align: center;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            color: #fff;
            z-index: 10;
            font-size: 13px;
            line-height: 22px;
            font-weight: 800;
        }

        .help-list{
            position: absolute;
            background-color: #f5f5f5;
            padding: 40px;
            width: 360px;
            left: 0;
            transform: translate(-50%, 0px);
            bottom: 55px;
            max-width: 100vw;

            ol{
                padding: 5px 0 5px 25px;
            }

            .close_btn{
                position: absolute;
                right: 10px;
                top: 10px;
                width: 20px;
                height: 20px;
                background: #ececec;
                /* display: block; */
                text-align: center;
                border-radius: 50%;
                font-size: 12px;
                line-height: 20px;
                cursor: pointer;
            }

            @media screen and (max-width: 980px) {
                top: 55px;
                bottom: initial;
                width: 340px;
                left: -50px;
            }
        }
    }

    .calendar {
        position: relative;

        &-wrapper {
            display: flex;

            @media (max-width: 980px) {
                display: block;
            }
        }

        &-inner {
            width: 33.3%;
            height: 100vh;
            background: url(../assets/images/calendar-bg.jpg) no-repeat center;
            background-size: cover;
            padding: 80px 52px 80px 80px;
        }

        &-title {
            font-weight: bold;
            font-size: 60px;
            line-height: 80px;
            letter-spacing: -0.42px;
            color: #ffffff;
            margin-bottom: 60px;
        }

        &-events-list {
            width: 33.3%;
            height: 100vh;
            padding-top: 79px;

            .calendar-event_header {
                padding-left: 40px;
                padding-right: 40px;
            }

            @media (max-width: 1100px) {
                width: 65% !important;
                max-width: 65% !important;
            }
            @media (max-width: 980px) {
                width: 100% !important;
                height: initial !important;
                max-width: 100% !important;
            }
        }

        &-event {
            @media (max-width: 1100px) {
                display: none;
            }
        }

        &-container {
            max-width: 480px;

            @media screen and (max-width: 575px) {
                width: 100%;
            }

            @media screen and (max-width: 1100px) {
                margin-left: -18px;
            }
        }
        &-filter {
            display: flex;
            flex-direction: column;
            color: #ffc900;

            &  div {
                display: flex;
                margin-bottom: 25px;
                position: relative;
                @media (max-width: 1363px) and (min-width: 1200px){
                    left: -36px;
                }

                & span {
                    color: white;
                    font-size: 0.8em;
                    position: absolute;
                    left: 120px;
                    width: fit-content;


                    &::before {
                        content: '';
                        height: 1px;
                        width: 10px;
                        background-color: #fff;
                        display: block;
                        position: absolute;
                        left: -15px;
                        top: 7px;
                    }
                }
            }
            & input[type='checkbox'] {
                opacity: 0;
                cursor: pointer;
            }
            & input[type='checkbox'] + label{
                width: 16px;
                height: 16px;
                display: block;
                border-radius: 4px;
                position: relative;
                margin-left: 20px;
                text-indent: -999px;
                border: 1px solid #ffc900;
                cursor: pointer;
                @media (max-width: 768px){
                    margin-left: 0;
                }
            }
            & input[type='checkbox']:checked + label {
                border: 1px solid #ffc900;
                background: #ffc900;
            }
            &_icon {
                height: 24px;
                top: -4px;
                left: 60px;
                width: 40px;
                position: absolute;
                display: block;
                text-transform: none;

            }

            @media screen and (max-width: 1100px) {
                input[type=checkbox] {
                    display: none;

                    & + label {
                        margin-left: -16px!important;
                    }
                }

                &_icon {
                    left: 25px;
                }

                div span {
                    left: 85px;
                    max-width: 70%;
                }
            }
        }

        .calendar-events {
            &_info {
                margin-right: 67px;
            }

            &_group {
                margin-bottom: 8px;
            }

            &_title {
                max-width: 340px;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.126px;
                color: #1a2744;
            }

            &_title.current {
                color: #ffc900;
            }
        }

        &-event {
            background: #f9f9f9;
            width: 33.3%;
            height: 100vh;
            padding: 79px 40px 100px 40px;
            overflow: auto;

            &_header {
                border-bottom: 1px solid rgb(0 0 0 / 7%);
                padding: 0 0 26px;
                overflow: hidden;
                h3 {
                    font-weight: bold;
                    font-size: 27px;
                    line-height: 42px;
                    color: #1a2744;
                    position: relative;
                    margin-top: 20px;
                }
                h4 {
                    font-weight: bold;
                    font-size: 27px;
                    line-height: 42px;
                    color: #1a2744;
                    position: relative;
                    margin-top: 20px;
                }

                h5 {
                    font-weight: normal;
                    font-size: 19px;
                    line-height: 24px;
                    letter-spacing: -0.14px;
                    color: #1a2744;
                    margin-top: 5px;
                }
            }

            &_body {
                padding: 20px 0 0;
            }

            &_img {
                max-width: 480px;
                height: 256px;
                margin-bottom: 40px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &_title {
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: -0.168px;
                color: #1a2744;
                margin-bottom: 10px;
            }

            &_text {
                font-weight: normal;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: -0.14px;
                color: #1a2744;
                margin-bottom: 40px;
            }

            &_footer {
                padding: 0 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &_pages {
                display: flex;
                align-items: center;

                a {
                    font-weight: 900;
                    font-size: 20px;
                    line-height: 50px;
                    letter-spacing: -0.14px;
                    color: #1a2744;
                    opacity: 0.4;
                    margin-right: 19px;
                    text-decoration: none;

                    &:last-child {
                        margin: 0;
                    }
                }

                .active {
                    font-weight: 900;
                    font-size: 40px;
                    line-height: 50px;
                    letter-spacing: -0.28px;
                    color: #ffc900;
                    opacity: 1;
                }
            }
        }
    }

    .header {
        width: 100%;
    }

    .header-logo {
        width: 253px;
        height: 51px;
        margin-bottom: 22px;
        display: inline-block;
    }


    /* breadcrumb */
    .breadcrumb {
        list-style: none;
        margin-bottom: 45px;
    }

    .breadcrumb-item {
        display: inline;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #fff;
        margin-right: 20px;
    }

    .breadcrumb-link {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #ffc900;
        text-decoration: none;
        position: relative;

        &::after {
            position: absolute;
            content: '';
            width: 1px;
            height: 15px;
            background: #fff;
            bottom: 1px;
            right: -11px;
        }
    }

    .calendar-event_body {
        img {
            max-width: 100% !important
        }
    }

    .calendar-events-list {
        overflow-y: auto;
        min-height: 310px;

        &__itemWrapper {
            padding: 30px 0;
        }
    }

    @media screen and (max-width: 1920px) {
        .calendar {
            &-inner {
                max-width: 640px;
                width: 33.3%;
                height: 100vh;
            }

            &-events-list {
                max-width: 640px;
                width: 33.3%;
                height: calc(100vh - 100px);
            }

            &-event {
                max-width: 640px;
                width: 33.3%;

                &_footer {
                    position: absolute;
                    bottom: 30px;
                }
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .calendar-inner {
            padding-top: 40px;
        }

        .calendar-events-list {
            padding-top: 40px;
        }

        .calendar-event {
            padding-top: 40px;
        }

        .calendar-title {
            font-size: 50px;
            line-height: 60px;
        }
    }

    @media screen and (max-width: 1440px) {
        .calendar-event_header h3 {
            font-size: 22px;
            line-height: 35px;
        }

        .calendar-event_header h5 {
            font-size: 18px;
            line-height: 25px;
        }

        .calendar-event_body {
            padding: 20px 0;
        }

        .calendar-event_img {
            margin-bottom: 20px;
        }

        .calendar-event_footer {
            padding: 0 10px;

            button {
                margin-right: 10px;
            }
        }

        .calendar-title {
            font-size: 30px;
            line-height: 40px;
        }

        .calendar-event_title {
            font-size: 20px;
            line-height: 25px;
        }

        .calendar-event_text {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }

        .calendar .calendar-events_title {
            font-size: 16px;
            line-height: 20px;
        }

        .calendar-inner {
            padding-left: 60px;
            padding-right: 60px;
        }
    }
    @media screen and (max-width: 980px) {
        .calendar-common {
            width: 100%;
            @media (max-width: 980px) {
                width: 100%;
                height: auto;

                .calendar-wrapper__slot {
                    display: flex;

                    .calendar-filter {
                        width: 50%;
                        margin-left: 40px;
                        margin-top: 15px;

                        @media (max-width: 700px) {
                            width: 100%;
                            margin-left: 0;
                        }
                    }

                    @media (max-width: 700px) {
                        display: block;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        .calendar-filter {
            //& div {
            //    & label {
            //        margin-left: 0px !important;
            //    }
            //    & svg {
            //        margin-left: -20px;
            //    }
            //    & span {
            //        margin-left: -20px;
            //        padding-right: 80px !important;
            //    }
            //}
        }
    }
    .common-block{
        min-width: 500px;
        max-width: 550px;

        @media screen and (max-width: 575px) {
            min-width: auto;
            padding-right: 20px;
        }
    }
</style>


<style lang="less">

    .highlight {
        background: #ffc900 !important;
    }
    .highlight-selected {
        background: #d6d6d6 !important;
        opacity: 0.5;
    }

    .vc-dots {
        position: absolute;
        right: 10px;
        top: 10px;

        @media screen and (max-width: 575px) {
            top: 5px;
        }
    }

    .vc-dot {
        width: 7px !important;
        height: 7px !important;
    }

    .dots {
        background-color: #1a2744 !important;

        &--past {
            background-color: #1a274462 !important;
        }
    }

    .body--dark {
        .dots {
            background-color: #ffc900 !important;

            &--past {
                background-color: #ffc8006c !important;
            }
        }
    }

    .vc-title {
        color: #fff !important;
    }

    .vc-arrow {
        border-radius: 50%;
        color: #fff;
    }

    .vc-arrow:hover {
        background: #ffc900 !important;
    }

    .vc-title {
        text-transform: capitalize;
    }

    .vc-header {
        color: #fff !important;
    }

    .calendar-container {
        .vc-container{
            color: #0f0f0f!important;
        }
    }
    .vc-highlights{
        ~ span{
            background-color: #ffc900!important;
            color: #0f0f0f!important;
        }
    }

    .party {
        .calendar-container {
            .vc-container{
                color: #0f0f0f!important;
            }
        }
        .vc-highlights{
            ~ span{
                background-color: #1A2744!important;
                color: #ffc900!important;
            }
        }
        .calendar-filter div span{
            color: #1a2744!important;
        }
        .vc-title{
            color: #1a2744!important;
        }
        .vc-arrows-container .vc-arrow svg{
            color: white!important;
            width: 40px;
            height: 40px;
            &:hover{
                color: #ffc900!important;
                background-color: #1a2744;
                border-radius: 50%;
            }
        }
    }
    .calendar-common{
        padding-bottom: 0;
        header{
            .party-member{
                margin-bottom: 3px;
            }
        }
        .slot{
            margin-top: 20px;
            margin-bottom: 0;
        }
        .vc-weeks{
            margin-top: 28px;
        }
        .body{
            .vc-day-content{
                height: 50px;
                width: 50px;

                @media screen and (max-width: 575px) {
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }
    .calendar-common.deputies{
        padding-bottom: 0;
        max-height: 100vh;

        @media screen and (max-width: 980px) {
            max-height: auto;
            max-width: 100%;
        }

        .slot{
            margin-top: 20px;
            margin-bottom: 0;
        }
        .body{
            .vc-title{
                color: white!important;
            }
            .vc-arrow svg{
                color: white!important;
            }
            .vc-weeks{
                margin-top: 28px;
                .vc-weekday{
                    color: #cdd4e0;
                }
            }
            .vc-day{
                color: white!important;
            }
            .vc-day-content{
                height: 50px;
                width: 50px;

                @media screen and (max-width: 575px) {
                    height: 40px;
                    width: 40px;
                }
            }
            .party .vc-highlights ~ span{
                background-color: #ffc900!important;
                color: white!important;
            }
            .party .calendar-filter div span{
                color: white!important;
            }

        }
        header{
            .router-link-active{
                color: #ffc900!important;
            }
            .party-member li:not(:first-child):before{
                background: #ffc900!important;
            }
            .party-member{
                margin-bottom: 3px;
            }
        }
    }
    .is-today{
        .vc-highlights{
            ~ span{
                background-color: white!important;
                color: #1a2744!important;
            }
        }
    }

        .common-block.yellow-color{

            @media screen and (max-width: 980px) {
                max-height: auto;
                max-width: 100%;
            }

            .breadcrumb{
                li:first-child a{
                    color: #1a2744;
                }
            }

            .calendar-filter input[type='checkbox']:checked + label {
                border: 1px solid white;
                background: white;
            }
            .calendar-filter input[type='checkbox'] + label{
                width: 16px;
                height: 16px;
                display: block;
                border-radius: 4px;
                position: relative;
                margin-left: 20px;
                text-indent: -999px;
                border: 1px solid white;
                cursor: pointer;
            }
        }
</style>

<style lang="scss">
    .calendar-container{
        .vc-pane-container{
            .vc-pane{
                min-width: auto!important;
                width: auto;
            }
        }
    }
</style>
